import { useProductStore } from '~~/stores/product.store'

export function useSurvey() {
  const route = useRoute()

  const surveyType = computed(() => {
    if (route.path.includes('product')) return 'product'
    else if (route.path.includes('company')) return 'company'
    return null
  })

  const isCompanySurvey = computed(() => surveyType.value === 'company')

  const baseUrl = computed(() => {
    if (!isCompanySurvey.value) {
      const { product } = storeToRefs(useProductStore())
      return `/dashboard/product/${product.value?.id}`
    }
    if (isCompanySurvey.value) return `/dashboard/company`

    return `/dashboard`
  })

  return {
    surveyType,
    isCompanySurvey,
    baseUrl,
  }
}
